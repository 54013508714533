import React from 'react';
import { graphql } from 'gatsby';

import FullWidthContainer from '../components/containers/fullWidthContainer';
import Layout from '../components/layout';
import HeroContent from '../components/heroContent';
import { RichText } from 'prismic-reactjs';
import { convertToBgImage } from 'gbimage-bridge';

const NotFoundPage = ({ data }) => {
  const prismicData = data.prismicNotFoundPage.data;

  const { title, banner_image, banner_graphic, main_content } = prismicData;

  const backgroundImage = convertToBgImage(banner_image.gatsbyImageData);
  const backgroundImageFluidStack = [`${banner_graphic.url}`, backgroundImage];

  return (
    <Layout
      backgroundImageFluidStack={backgroundImageFluidStack}
      logoClassName='max-w-xxxxs xl:max-w-xxxs mega:max-w-xxs'
      contentInsideBackground={() => (
        <FullWidthContainer className='xl:mt-6 mega:mt-16'>
          <HeroContent
            heroTitle={RichText.asText(title.richText)}
            heroDescription={main_content.richText}
            rightAlign
            isTitleOnBottom
          />
        </FullWidthContainer>
      )}
    >
      <span></span>
    </Layout>
  );
};

export const query = graphql`
  {
    prismicNotFoundPage {
      data {
        banner_graphic {
          url
          alt
          gatsbyImageData
        }
        main_content {
          richText
        }
        banner_image {
          alt
          gatsbyImageData
        }
        title {
          richText
        }
      }
    }
  }
`;

export default NotFoundPage;
